import React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  DialogProps as MuiDialogProps,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import * as styles from './dialog.module.less';

export interface DialogProps {
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
  overridePosition?: any;
  visibleOverflow?: boolean;
  maxWidth?: MuiDialogProps['maxWidth'];
}

export const MUIDialog: React.FC<DialogProps> = ({
  children,
  open,
  onClose,
  overridePosition,
  visibleOverflow,
  maxWidth = 'sm',
}: DialogProps) => {
  return (
    <Dialog
      PaperProps={{ className: visibleOverflow && styles.visibleOverflow }}
      fullWidth
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      style={overridePosition}
      className={styles.dialog}
    >
      {children}
    </Dialog>
  );
};

export interface DialogTitleProps {
  onClose: () => void;
  title: string;
  divider?: boolean;
  hideX?: boolean;
  titleStyle?: string;
}

export const MUIDialogTitle = (props: DialogTitleProps) => {
  const { onClose, title, divider, hideX = false, titleStyle, ...other } = props;
  return (
    <>
      <DialogTitle disableTypography className={styles.root} {...other}>
        <Typography variant="h6" className={titleStyle}>
          {title}
        </Typography>
        {onClose && !hideX ? (
          <IconButton aria-label="close" className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {divider && <Divider />}
    </>
  );
};

export const MUIDialogContent = ({ children, divider = false }) => {
  return (
    <>
      <DialogContent> {children}</DialogContent>
      {divider && <Divider />}
    </>
  );
};

export const MUIDialogActions = ({ children }) => {
  return <DialogActions>{children}</DialogActions>;
};
