import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import TeamAdminRightsForm from 'src/components/team-admin-rights-form/team-admin-rights-form';
import TeamContactDetailsForm from 'src/components/team-contact-details-form/team-contact-details-form';
import TeamRolesAndResponsibilitiesForm from 'src/components/team-roles-and-responsibilities-form/team-roles-and-responsibilities-form';
import {
  AddTeamMemberActiveSteps,
  addTeamMemberInitialValues,
  AddTeamMemberVisitedSteps,
  addTeamMemberVisitedSteps,
} from 'src/utils/typedefs/team';
import * as Yup from 'yup';

import * as styles from './team-add-member.module.less';

const TeamAddMember: React.FC = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState<AddTeamMemberActiveSteps>(AddTeamMemberActiveSteps.ContactDetails);
  const [visitedSteps, setVisitedSteps] = useState<AddTeamMemberVisitedSteps>(addTeamMemberVisitedSteps);

  const validationSchema = Yup.object().shape({
    contactDetails: Yup.object().shape({
      contact: Yup.object().shape({
        firstName: Yup.string().required(t('is required', { type: 'first name' })),
        lastName: Yup.string().required(t('is required', { type: 'last name' })),
        email: Yup.string()
          .email(t('email not valid'))
          .required(t('is required', { type: 'first name' }))
          .email(),
        dateOfBirth: Yup.string().required(t('is required', { type: 'date of birth' })),
        phone: Yup.string().required(t('is required', { type: 'phone' })),
      }),
    }),
    rolesAndResponsibilities: Yup.object()
      .shape({
        president: Yup.boolean(),
        'tennis director': Yup.boolean(),
        'general manager': Yup.boolean(),
        'membership co-ordinator': Yup.boolean(),
        'head coach': Yup.boolean(),
        coach: Yup.boolean(),
        'assistant coach': Yup.boolean(),
      })
      .test('rolesAndResponsibilities', t('checkbox validation message'), (value) =>
        Object.values(value).includes(true),
      ),
    adminRights: Yup.object()
      .shape({
        'super administrator': Yup.boolean(),
        administrator: Yup.boolean(),
        'membership administrator': Yup.boolean(),
        'coaching administrator': Yup.boolean(),
        'contacts administrator': Yup.boolean(),
        'website administrator': Yup.boolean(),
        'booking administrator': Yup.boolean(),
      })
      .test('adminRights', t('checkbox validation message'), (value) => Object.values(value).includes(true)),
  });

  const handleChangeStep = (step: AddTeamMemberActiveSteps, visitedSteps: AddTeamMemberVisitedSteps) => {
    setStep(step);
    setVisitedSteps(visitedSteps);
  };

  return (
    <Grid container direction="column">
      <h1 className={styles.mainHeading}>{t('add team member')}</h1>
      <Formik
        initialValues={addTeamMemberInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {(formikProps) => (
          <Form translate="" name="team-add-member">
            <TeamContactDetailsForm
              formikProps={formikProps}
              step={step}
              visitedSteps={visitedSteps}
              setStep={handleChangeStep}
            />
            <TeamRolesAndResponsibilitiesForm
              formikProps={formikProps}
              step={step}
              visitedSteps={visitedSteps}
              setStep={handleChangeStep}
            />
            <TeamAdminRightsForm
              formikProps={formikProps}
              step={step}
              visitedSteps={visitedSteps}
              setStep={handleChangeStep}
            />
            <Button
              disabled={step !== AddTeamMemberActiveSteps.Done}
              size="md"
              type="submit"
              spacing={{ margins: { lg: 'top' } }}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default TeamAddMember;
